import ApiService from "@/core/services/api/v2/api.service";

const FARE_STANDARD_FILTER_KEY = "FareStandardFilter";

const FareStandardService = {
  getProfile(fareStandardID) {
    return new Promise(resolve => {
      ApiService.get("fare/standard/" + fareStandardID)
        .then(response => {
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  updateProfile(fareStandardID, newData) {
    return new Promise(resolve => {
      ApiService.put("fare/standard/" + fareStandardID, newData)
        .then(response => {
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  deleteProfile(fareStandardID) {
    return new Promise(resolve => {
      ApiService.delete("fare/standard/" + fareStandardID)
        .then(response => {
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  createProfile(newData) {
    return new Promise(resolve => {
      ApiService.put("fare/standard", newData)
        .then(response => {
          // Response (if correct) holds the new FareStandardID
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  search(
    searchText,
    filterByOriginProvinceID,
    filterByDestinationProvinceID,
    orderBy,
    orderDesc,
    limit,
    offset
  ) {
    return new Promise(resolve => {
      // Parse params - storing a cache of the filters applied
      const queryParams = {};

      searchText = searchText.trim();
      if (searchText.length > 0) {
        queryParams.Search = searchText;
      }

      filterByOriginProvinceID = parseInt(filterByOriginProvinceID);
      if (!isNaN(filterByOriginProvinceID) && filterByOriginProvinceID > 0) {
        queryParams.OriginProvinceID = filterByOriginProvinceID;
      }

      filterByDestinationProvinceID = parseInt(filterByDestinationProvinceID);
      if (
        !isNaN(filterByDestinationProvinceID) &&
        filterByDestinationProvinceID > 0
      ) {
        queryParams.DestinationProvinceID = filterByDestinationProvinceID;
      }

      window.sessionStorage.setItem(
        FARE_STANDARD_FILTER_KEY,
        JSON.stringify(queryParams)
      );

      orderBy = orderBy ? orderBy.trim() : "";
      if (orderBy.length > 0) {
        queryParams.OrderBy = orderBy;
      }

      queryParams.OrderDesc = orderDesc ? 1 : 0;

      limit = parseInt(limit);
      if (!isNaN(limit) && limit > 0) {
        queryParams.Limit = limit;
      }

      offset = parseInt(offset);
      if (!isNaN(offset)) {
        queryParams.Offset = offset;
      }

      // Do search
      ApiService.query("fare/standard", { params: queryParams })
        .then(response => {
          // Return full response, not just "FareStandards" array, for pagination etc
          resolve(response.data);
        })
        .catch(() => {
          resolve(404);
        });
    });
  },

  getCachedFilters() {
    let cachedFilters = window.sessionStorage.getItem(FARE_STANDARD_FILTER_KEY);
    if (cachedFilters) {
      return JSON.parse(cachedFilters);
    } else {
      return {};
    }
  },

  downloadExcel() {
    return new Promise(resolve => {
      ApiService.getBlob("fare/standard/export")
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  uploadExcel(newFile) {
    return new Promise(resolve => {
      const formData = new FormData();
      formData.append("Excel", newFile);

      ApiService.post("fare/standard/import", formData)
        .then(response => {
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  }
};

export default FareStandardService;
